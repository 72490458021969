@media (max-width: 400px) {
    .h-full {
      height: auto;
    }
    .w-full {
      width: 100%;
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    .justify-center {
      justify-content: center;
    }
    .text-6xl {
      font-size: 1.5rem;
    }
    .text-xl {
      font-size: 1.0rem;
    }
    /* Add more CSS styles as needed for smaller screens */
  }