.mod {
  border: 5px double black;
}

@media (max-width: 767px) {
    .text-3xl {
      font-size: 2rem;
    }
  }

@media (max-width: 400px) {
    .text-3xl {
      font-size: 1rem;
    }

}