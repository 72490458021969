@media (min-width: 768px) {
    .grid-cols-2 > *:nth-child(4n+1) {
      grid-column-start: 1;
    }
  
    .grid-cols-2 > *:nth-child(4n+2) {
      grid-column-start: 2;
    }
  
    .grid-cols-2 > *:nth-child(4n+3) {
      grid-column-start: 1;
    }
  
    .grid-cols-2 > *:nth-child(4n+4) {
      grid-column-start: 2;
    }
}

