/* responsive.css */

.gallery {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
}

.state {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.state.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.preview-img {
  width: 40%;
}

@media (max-width: 767px) {
    .h-full {
      height: auto;
    }
    .w-full {
      width: 100%;
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    .justify-center {
      justify-content: center;
    }
    .text-4xl {
      font-size: 2rem;
    }
    .text-xl {
      font-size: 1.5rem;
    }
    .gallery-div {
      flex-wrap: wrap;
    }
    /* Add more CSS styles as needed for smaller screens */
  }

  @media (max-width: 400px) {
    .gallery-div {
      flex-wrap: wrap;
    }
    .text-4xl {
      font-size: 1.5rem;
    }
    .text-xl {
      font-size: 1rem;
    }
  }