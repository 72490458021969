@import url("tailwind.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comforter+Brush&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comforter+Brush&family=Corinthia&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comforter+Brush&family=Corinthia&family=Yuji+Boku&display=swap");



html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  /* background-color: #16181d; */
  /* background-color: #040404;
  color: #fff; */
  font-family: "Poppins", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.logo {
  font-family: "Yuji Boku", cursive;
}

.prev:hover {
  filter: grayscale(100%);
  transition: all 0.3s linear;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
}

.modal-image {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 90vw;
  height: 70vh;
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  color: #000;
  width: 50px;
  height: 50px;
  font-size: 50px;
  cursor: pointer;
  background-color: #fff;
}

.close-button:hover {
  background: #000;
  color: #fff;
}

.prev-button,
.next-button {
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
  background-color: #fff;
  color: #000000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.prev-button:hover,
.next-button:hover {
  background-color: #000000;
  color: #fff;
}

.text-bg {
  background-color: bisque;
  color: black;
  padding: 10px 0;
}

.splashLogo {
  font-family: "Poppins", cursive;
  font-weight: bold;
  font-size: 10vw;
  line-height: 0.9;
}

.vertical-rotate {
  transform: rotate(180deg);
  text-transform: uppercase;
  writing-mode: vertical-lr;
}

.anim_glitch {
  animation: glitch 3000ms linear infinite;
  &:hover {
    animation: glitch_subtle 3000ms linear infinite;
  }
}

@keyframes glitch {
  19% {
    transform: none; 
  }
  20% {
    transform: skew(60deg);
  }
  21% {
    transform: none;
  }
  
  29% {
    transform: none;
  }
  30% {
    transform: translate(5px, 5px);
  }
  31% {
    transform: none;
  }
  
  34% {
    transform: none;
  }
  35% {
    transform-origin: 100% 100%;
    transform: scale(1.5);
  }
  36% {
    transform-origin: initial;
    transform: none;
  }
  
  69% {
    transform: none;
  }
  70% {
    transform-origin: 0 100%;
    transform: scale(1.5) translate(-33%, -33%);
  }
  71% {
    transform-origin: initial;
    transform: none;
  }
  
  74% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  
  89% {
    transform: none;
  }
  90% {
    transform: translate(2px, 0) skew(30deg);
  }
  91% {
    transform: translate(-2px, 0);
  }
  92% {
    transform: none;
  }
}

@keyframes glitch_subtle {
  19% {
    transform: none;
  }
  20% {
    transform: translate(2px, 2px);
  }
  21% {
    transform: none;
  }
  
  29% {
    transform: none;
  }
  30% {
    transform: translate(-2px, 2px);
  }
  31% {
    transform: none;
  }
  
  59% {
    transform: none;
  }
  60% {
    transform: scale(0.8);
  }
  61% {
    transform: none;
  }
  
  74% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  
  89% {
    transform: none;
  }
  90% {
    transform: translate(2px, 2px) skew(20deg);
  }
  91% {
    transform: none;
  }
}

@media (min-width: 768px) {
  .modal-image {
    width: auto;
    height: auto;
  }
}

@media (min-width: 200px) {
.modal-image {
  width: auto;
  height: auto;
}
}