@media (max-width: 767px) {
    .h-full {
      height: auto;
    }
    .w-full {
      width: 100%;
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    .justify-center {
      justify-content: center;
    }
    .text-5xl {
      font-size: 2rem;
    }
    .text-3xl {
      font-size: 1.5rem;
    }
    .div {
      flex-wrap: wrap;
    }
    /* Add more CSS styles as needed for smaller screens */
  }

  @media (max-width: 400px) {
    .div {
      flex-wrap: wrap;
    }
    .text-5xl {
      font-size: 1.5rem;
    }
    .text-3xl {
      font-size: 1rem;
    }
  }