/* responsive.css */

@media (max-width: 767px) {
    .h-full {
      height: auto;
    }
    .w-full {
      width: 100%;
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    .justify-center {
      justify-content: center;
    }
    .text-4xl {
      font-size: 2rem;
    }
    .text-xl {
      font-size: 1.5rem;
    }
    .project-div {
      flex-wrap: wrap;
    }
    /* Add more CSS styles as needed for smaller screens */
  }


@media (max-width: 400px) {
  .project-div {
    flex-wrap: wrap;
  }
  .text-4xl {
    font-size: 1.5rem;
  }
  .text-xl {
    font-size: 1rem;
  }
}

  