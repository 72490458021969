.name {
    font-size: 5rem;
    margin: 10px;
}
.btn-actions {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    gap: 25px;
    text-align: center;
}

#projectsbutton, #designsbutton, #morebutton, #hirebutton {
   padding: 1rem 2rem 1rem 2rem;
}

#projectsbutton:hover{
   background: rgba(255, 255, 255, 0.521);
}

#designsbutton:hover{
   background: rgba(255, 255, 255, 0.521);
}

#morebutton:hover{
   background: rgba(255, 255, 255, 0.521);
}

#hirebutton:hover{
   background: rgba(255, 255, 255, 0.521);
}

#hirebuttonlabel{
   text-wrap: nowrap;
}

.introduction {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.glitch-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
 }
 
 .glitch {
    position: relative;
    font-size: 80px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    letter-spacing: 5px;
    z-index: 1;
 }
 
 .glitch:before,
 .glitch:after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
 }
 
 .glitch:before {
    animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
    color: #0ff;
    z-index: -1;
 }
 
 .glitch:after {
    animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
    color: #ff00ff;
    z-index: -2;
 }
 
 @keyframes glitch-color {
    0% {
       transform: translate(0);
    }
 
    20% {
       transform: translate(-3px, 3px);
    }
 
    40% {
       transform: translate(-3px, -3px);
    }
 
    60% {
       transform: translate(3px, 3px);
    }
 
    80% {
       transform: translate(3px, -3px);
    }
 
    to {
       transform: translate(0);
    }
 }

 .glitch-v2 {
    position: relative;
    font-size: 80px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    letter-spacing: 5px;
    z-index: 1;
 }
 
 .glitch-v2:before {
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: -2px;
    width: 100%;
    color: #fff;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-before 3s infinite linear alternate-reverse;
 }
 
 .glitch-v2:after {
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 2px;
    width: 100%;
    color: #fff;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-after 2s infinite linear alternate-reverse;
 }
 
 @keyframes noise-before {
    0% {
       clip: rect(61px, 9999px, 52px, 0);
    }
 
    5% {
       clip: rect(33px, 9999px, 144px, 0);
    }
 
    10% {
       clip: rect(121px, 9999px, 115px, 0);
    }
 
    15% {
       clip: rect(144px, 9999px, 162px, 0);
    }
 
    20% {
       clip: rect(62px, 9999px, 180px, 0);
    }
 
    25% {
       clip: rect(34px, 9999px, 42px, 0);
    }
 
    30% {
       clip: rect(147px, 9999px, 179px, 0);
    }
 
    35% {
       clip: rect(99px, 9999px, 63px, 0);
    }
 
    40% {
       clip: rect(188px, 9999px, 122px, 0);
    }
 
    45% {
       clip: rect(154px, 9999px, 14px, 0);
    }
 
    50% {
       clip: rect(63px, 9999px, 37px, 0);
    }
 
    55% {
       clip: rect(161px, 9999px, 147px, 0);
    }
 
    60% {
       clip: rect(109px, 9999px, 175px, 0);
    }
 
    65% {
       clip: rect(157px, 9999px, 88px, 0);
    }
 
    70% {
       clip: rect(173px, 9999px, 131px, 0);
    }
 
    75% {
       clip: rect(62px, 9999px, 70px, 0);
    }
 
    80% {
       clip: rect(24px, 9999px, 153px, 0);
    }
 
    85% {
       clip: rect(138px, 9999px, 40px, 0);
    }
 
    90% {
       clip: rect(79px, 9999px, 136px, 0);
    }
 
    95% {
       clip: rect(25px, 9999px, 34px, 0);
    }
 
    100% {
       clip: rect(173px, 9999px, 166px, 0);
    }
 }
 
 @keyframes noise-after {
    0% {
       clip: rect(26px, 9999px, 33px, 0);
    }
 
    5% {
       clip: rect(140px, 9999px, 198px, 0);
    }
 
    10% {
       clip: rect(184px, 9999px, 89px, 0);
    }
 
    15% {
       clip: rect(121px, 9999px, 6px, 0);
    }
 
    20% {
       clip: rect(181px, 9999px, 99px, 0);
    }
 
    25% {
       clip: rect(154px, 9999px, 133px, 0);
    }
 
    30% {
       clip: rect(134px, 9999px, 169px, 0);
    }
 
    35% {
       clip: rect(26px, 9999px, 187px, 0);
    }
 
    40% {
       clip: rect(147px, 9999px, 137px, 0);
    }
 
    45% {
       clip: rect(31px, 9999px, 52px, 0);
    }
 
    50% {
       clip: rect(191px, 9999px, 109px, 0);
    }
 
    55% {
       clip: rect(74px, 9999px, 54px, 0);
    }
 
    60% {
       clip: rect(145px, 9999px, 75px, 0);
    }
 
    65% {
       clip: rect(153px, 9999px, 198px, 0);
    }
 
    70% {
       clip: rect(99px, 9999px, 136px, 0);
    }
 
    75% {
       clip: rect(118px, 9999px, 192px, 0);
    }
 
    80% {
       clip: rect(1px, 9999px, 83px, 0);
    }
 
    85% {
       clip: rect(145px, 9999px, 98px, 0);
    }
 
    90% {
       clip: rect(121px, 9999px, 154px, 0);
    }
 
    95% {
       clip: rect(156px, 9999px, 44px, 0);
    }
 
    100% {
       clip: rect(67px, 9999px, 122px, 0);
    }
 }


 @media (max-width: 767px) {
   .h-full {
     height: auto;
   }
   .w-full {
     width: 100%;
   }
   .flex-wrap {
     flex-wrap: wrap;
   }
   .justify-center {
     justify-content: center;
   }
   .text-4xl {
     font-size: 2rem;
   }
   .text-xl {
     font-size: 1.5rem;
   }
   .glitch {
      font-size: 3.5rem;
   }
   .glitch-v2 {
      font-size: 3.5rem;
   }
   .btn-actions {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
  }
   /* Add more CSS styles as needed for smaller screens */
 }

 @media (max-width: 420px) {
   .btn-actions {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
  }
   .glitch {
      font-size: 2.5rem;
   }
   .glitch-v2 {
      font-size: 2.5rem;
   }
 }

 @media (max-width: 400px) {
   .btn-actions {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
  }
   .glitch {
      font-size: 2rem;
   }
   .glitch-v2 {
      font-size: 2rem;
   }
   .text-4xl {
     font-size: 1.5rem;
   }
   .text-xl {
     font-size: 1rem;
   }
 }